<template>
  <div style="width:1200px;margin: auto;margin-top: 50px;" v-loading="loading">

    <video-player class="video-player vjs-custom-skin m_b_20"
                  ref="videoPlayer"
                  :playsinline="playsinline"
                  :options="playerOptions"
                  v-if="resourceInfo.types === 'm3u8'||resourceInfo.types === 'mp4'"
                  v-show="showVideo"
    />
    <audio :src="resourceInfo.resourcesUrl"
           v-show="resourceInfo.types === 'mp3'"
           controls></audio>
    <div class="course-info-box flex_row m_b_20 bg_fff br_5" style="height: 276px;">
      <img class="cover-img br_5"
           :src="resourceInfo.coverUrl?imgToRespomse(resourceInfo.coverUrl):imgJSON[resourceInfo.types]"
           :alt="resourceInfo.name"
      >
      <div class="fs_16 p_lr_20 m_tb_10 w80">
        <div class="flex_row">
          <span class="fs_24 m_r_30 text_hidden fw_bold" style="display: inline-block;max-width:465px;">
            {{ resourceInfo.name }}
          </span>
          <el-tag class="m_r_30" style="margin-top:8px;" size="mini">{{ resourceInfo.types }}</el-tag>
          <!-- <div>
            <img class="m_r_30 pointer"
                 style="margin-top:5px;"
                 width="23px"
                 @click="collectionClick(resourceInfo)"
                 :src="resourceInfo.collectId>0?collectionSelect:collection"
                 alt="">
            <img class="m_r_30 pointer"
                 style="margin-top:5px;"
                 width="23px"
                 @click="likeClick(resourceInfo)"
                 :src="resourceInfo.likeId>0?likeSelect:like"
                 alt="">
          </div> -->
        </div>
        <div class="m_tb_10" style="margin-left: 3px;">{{ resourceInfo.remarks1 }}</div>
        <!-- <div class="fs_14  c_666">
          <div class="m_b_10">
            <span class="collection">
              <span class="fs_14" style="margin-left: 20px;color:rgba(0,0,0,0.4)">收藏人数：</span>
              <span style="margin-left: 20px;color:rgba(0,0,0,0.6)">{{ resourceInfo.collectCount }} 人</span>
            </span>
          </div>
          <div class="m_b_10">
            <span class="like">
              <span class="fs_14" style="margin-left: 20px;color:rgba(0,0,0,0.4)">点赞人数：</span>
              <span style="margin-left: 20px;color:rgba(0,0,0,0.6)">{{ resourceInfo.likeCount }} 人</span>
            </span>
          </div>
          <div class="m_b_10">
            <span class="browse">
              <span class="fs_14" style="margin-left: 20px;color:rgba(0,0,0,0.4)">浏览人数：</span>
              <span style="margin-left: 20px;color:rgba(0,0,0,0.6)">{{ resourceInfo.browseNumber }} 人</span>
            </span>
          </div>
        </div> -->
        <div class="btn-box flex_row fs_16">
          <div class="btn c_fff br_5 pointer"
               @click="previewClick(resourceInfo)"
               v-show="resourceInfo.types!=='mp3'">
            预览
          </div>
          <!-- <div class="btn br_5 m_lr_20 pointer"
               @click.prevent="download"
               v-show="resourceInfo.types!=='html'">
            下载
          </div> -->
        </div>
      </div>
    </div>
    <div class="flex_row" style="justify-content: space-between;">
      <div class="bg_fff br_5 p_in_20 resource_head"
           :style="`width:${enclosureList.length!==0?'64%':'100%'};`"
           v-show="resourceInfo.introduce"
      >
        <div class="fs_20 fw_bold">资源介绍</div>
        <div class="m_tb_20" v-html="resourceInfo.introduce"></div>
      </div>
      <div class="bg_fff br_5 p_in_20 resource_head"
           style="width: 34%;"
           v-if="enclosureList.length!==0">
        <div class="fs_20 fw_bold">资源附件</div>
        <div class="resource-enclosure-box flex_row_wrap">
          <ResourceEnclosure class="resource-enclosure m_t_15" v-for="enclosureInfo of enclosureList"
                             :key="enclosureInfo.id"
                             :enclosureInfo="enclosureInfo"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {imgToRespomse} from '@/utils/imgToResponse'
  import {
    getDetailById_api
  } from "@/api/resourceCenter/school/center"
  import word from "assets/image/word.png";
  import pdf from "assets/image/pdf.png";
  import ppt from "assets/image/ppt.png";
  import excel from "assets/image/excel.png";
  import video from "assets/image/video.png";
  import voice from "assets/image/voice.png";
  // import {downloadFile} from "utils/downloadFile";
  import like from '@/assets/image/like.png'
  import likeSelect from '@/assets/image/like2.png'
  import collection from '@/assets/image/collection.png'
  import collectionSelect from '@/assets/image/collection2.png'
  import share from '@/assets/image/share.png'
  import {sessionGet} from "utils/local";
  //import axios from "axios";
  import {
    collectResource,
    likeResource
  } from "@/api/resourceCenter/school";
  // import ResourceEnclosure from "@/views/resourceCenter/components/region/ResourceEnclosure";

  export default {
    name: 'Details',
    components: {
      // ResourceEnclosure
    },
    data: () => {
      return {
        dialogCode: true,
        form: {
          code: '',
        },
        rules: {
          code: [{
            pattern: /[0-9]/,
            required: true,
            message: '请输入正确提取码',
            trigger: 'blur'
          }, ]
        },
        resourceInfo: {
          teacherInfo: []
        },
        enclosureList: [],
        imgJSON: {
          doc: word,
          pdf: pdf,
          pptx: ppt,
          xlsx: excel,
          txt: excel,
          mp4: video,
          m3u8: video,
          mp3: voice,
        },
        typeJSON: {
          doc: 'word',
          pdf: 'pdf',
          pptx: 'ppt',
          xlsx: 'excel',
          mp4: 'mp4',
          m3u8: 'm3u8',
          mp3: 'mp3',
          jpg: 'jpg',
          png: 'png'
        },
        loading: true,
        likeLock: false,
        collectLock: false,
        // 图片
        like,
        likeSelect,
        collection,
        collectionSelect,
        share,
        scene: '',
        isVerify: false,
        resourcesId: '',
        dialogVisible: false,
        token: sessionGet('tk'),
        Authorization: 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
        // 播放器配置
        playsinline: true,
        playerOptions: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
          controls: true, //是否显示控制条
          autoplay: false, // 如果为true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 是否视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: 'video/mp4',
              src: ''// url地址
            }],
          poster: '', // 封面地址
          notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            // timeDivider: true, // 当前时间和持续时间的分隔符
            // durationDisplay: true, // 显示持续时间
            // remainingTimeDisplay: false, // 是否显示剩余时间功能
            // fullscreenToggle: true // 是否显示全屏按钮
            volumeControl: true
          }
        },
        showVideo: false,
      }
    },
    watch: {
      $route: {
        handler() {
          let resourceId = this.$route.query.id
          this.resourcesId = resourceId;
          this.resourceDetails(resourceId)
        },
        immediate: true
      }
    },
    computed: {
      bladeAuth() {
        if (this.token) {
          return this.token.token_type + ' ' + this.token.access_token
        } else {
          return null
        }
      }
    },
    methods: {
      imgToRespomse(url) {
        return imgToRespomse(url)
      },
      // 获取资源详情
      resourceDetails(id) {
        getDetailById_api({
          id
        }).then(res => {
          if (res.code !== 200) return
          let data = res.data
          console.log(data);
          // data.teacherInfo = data.introduce.split('</br>')
          this.resourceInfo = data
          //this.enclosureList = data.enclosureList
          this.loading = false
        })
      },
      // 处理获取的数据
      processingData(resourceList) {
        resourceList.forEach(data => {
          data.teacherInfo = data.introduce.split('</br>')
        })
        return resourceList
      },
      // 预览
     previewClick(resourceInfo) {
       document.body.scrollTop = 0;
       document.documentElement.scrollTop = 0;
       if (resourceInfo.types === 'm3u8') {
         this.playerOptions.sources[0].type = 'application/x-mpegURL'
         this.playerOptions.sources[0].src = this.m3u8Url(resourceInfo.resourcesUrl)
         this.showVideo = true
       } else if (resourceInfo.types === 'mp4') {
         this.playerOptions.sources[0].type = 'video/mp4';
         this.playerOptions.sources[0].src = this.m3u8Url(resourceInfo.resourcesUrl)
         this.showVideo = true
       } else if (resourceInfo.types === 'mp3') {
         this.showVideo = true
       } else if (resourceInfo.types === 'html') {
         window.open(resourceInfo.remarks2)
       } else {
         // window.open('http://172.16.11.70:8012/onlinePreview?url=' + encodeURIComponent(btoa(resourceInfo.resourcesUrl)));
         window.open('http://139.155.146.142:8012/onlinePreview?url=' + encodeURIComponent(btoa(resourceInfo.resourcesUrl)));
       }
     },
      // 下载
     download() {
      // let userInfo = sessionGet('userInfo')

      // if (userInfo) {
         let resourceInfo = this.resourceInfo
        // axios.get('/api/blade-user/info?id=' + userInfo.user_id).then((res) => {
          // if (res && res.code === 200) {

             //window.open(`http://139.155.82.57:8081/fileDownload/download?id=${resourceInfo.id}&Authorization=${this.Authorization}&Blade-Auth=${this.bladeAuth}`);
             //window.location.href(`http://139.155.82.57:8081/fileDownload/download?id=${resourceInfo.id}&Authorization=${this.Authorization}&Blade-Auth=${this.bladeAuth}`);
             let downloadElement = document.createElement('a')

             // downloadElement.href = `http://172.16.10.233:8081/fileDownload/download?id=${resourceInfo.id}&Authorization=${this.Authorization}&Blade-Auth=${this.bladeAuth}`
             // downloadElement.download = `http://172.16.10.233:8081/fileDownload/download?id=${resourceInfo.id}&Authorization=${this.Authorization}&Blade-Auth=${this.bladeAuth}`

             // downloadElement.href = `http://139.155.82.57:8081/fileDownload/download?id=${resourceInfo.id}&Authorization=${this.Authorization}&Blade-Auth=${this.bladeAuth}`
             // downloadElement.download = `http://139.155.82.57:8081/fileDownload/download?id=${resourceInfo.id}&Authorization=${this.Authorization}&Blade-Auth=${this.bladeAuth}`

             downloadElement.href = `https://www.wuhousmartedu.com/fileDownload/download?id=${resourceInfo.id}&Authorization=${this.Authorization}&Blade-Auth=${this.bladeAuth}`
             //downloadElement.download = `https://www.wuhousmartedu.com/fileDownload/download?id=${resourceInfo.id}&Authorization=${this.Authorization}&Blade-Auth=${this.bladeAuth}`

             //downloadElement.target = '_blank'
             downloadElement.click()
          // }
        // })
      // } else {
       //  this.$message.error('请登录')
     //   this.$router.push('/login')
     //  }
     },

      // 点赞
      likeClick(resource) {
        if (this.likeLock) return this.$message.warning('请勿重复点击')
        let params = {
          id: resource.id,
          type: resource.likeId > 0 ? 0 : 1
        }
        this.likeLock = true
        likeResource(params).then(res => {
          if (res.code === 200) {
            if (resource.likeId > 0) {
              this.$message.success('取消点赞成功')
              this.resourceInfo.likeId = -1
              this.resourceInfo.likeCount -= 1
            } else {
              this.$message.success('点赞成功')
              this.resourceInfo.likeId = 1
              this.resourceInfo.likeCount += 1
            }
          } else {
            this.$message.error('操作失败')
          }

          this.likeLock = false
        })
      },
      // 收藏
      collectionClick(resource) {
        if (this.collectLock) return this.$message.warning('请勿重复点击')
        let params = {
          id: resource.id,
          type: resource.collectId > 0 ? 0 : 1
        }
        this.collectLock = true
        collectResource(params).then(res => {
          if (res.code === 200) {
            if (resource.collectId > 0) {
              this.$message.success('取消收藏成功')
              this.resourceInfo.collectId = -1
              this.resourceInfo.collectCount -= 1
            } else {
              this.$message.success('收藏成功')
              this.resourceInfo.collectId = 1
              this.resourceInfo.collectCount += 1
            }
          } else {
            this.$message.error('操作失败')
          }

          this.collectLock = false
        })
      },
      // 分享
      shareClick() {
        let link = window.location.href
        navigator.clipboard.writeText(link)
        this.$message.success('复制连接成功')
      },
      // 处理m3u8视频地址
      m3u8Url(url) {
        let type=url.split(".")[url.split(".").length-1];
        if(url.split(":")[0]=='https'&&type!='m3u8'){
          return url
        }else{
          let arr = url.split('/')
          arr[2] = 'www.wuhousmartedu.com'
          return arr.join('/')
        }
      }

    }
  }
</script>
<style scoped>
  .resource_head {
    justify-content: space-between;
  }

  .course_body {
    justify-content: space-between;
  }

  .w80 {
    width: 65%
  }

  .collection {
    position: relative;
  }

  .collection::before {
    content: url("../../../../assets/image/collection.png");
    position: absolute;
  }

  .like {
    position: relative;
  }

  .like::before {
    content: url("../../../../assets/image/like.png");
    position: absolute;
  }

  .browse::before {
    content: url("../../../../assets/image/eye.png");
    position: absolute;
  }

  .download-btn a {
    color: #67c23a;
  }

  .download-btn a:hover {
    color: #fff;
  }

  .download-btn:hover a {
    color: #fff;
  }

  .btn-box {
    margin-top: 50px;
  }

  .btn {
    width: 180px;
    height: 42px;
    line-height: 42px;
    text-align: center;
  }

  .btn:nth-child(1) {
    background-color: #12C294;
  }

  .btn:nth-child(2) {
    border: 1px solid #12C294;
    color: #12C294;
  }

  .cover-img {
    height: 100%;
    max-width: 490px;
    object-fit: cover;
  }

  .resource-enclosure {
    margin-right: 28px;
  }

  .resource-enclosure:hover {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05),
    0 5px 10px rgba(0, 0, 0, 0.1);
  }

  .resource-enclosure:nth-child(2n) {
    margin-right: 0;
  }
</style>
